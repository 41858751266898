export const whirlyPresets = {
  "Whirly-Birdie-Regular": {
    weight: 75,
    width: 110,
    italic: 0,
  },
  "Whirly-Birdie-Italic": {
    weight: 75,
    width: 110,
    italic: 12,
  },
  "Whirly-Birdie-Bold": {
    weight: 100,
    width: 110,
    italic: 0,
  },
  "Whirly-Birdie-Bold-Italic": {
    weight: 100,
    width: 110,
    italic: 12,
  },

  "Whirly-Birdie-Light": {
    weight: 50,
    width: 110,
    italic: 0,
  },
  "Whirly-Birdie-Light-Italic": {
    weight: 50,
    width: 110,
    italic: 12,
  },

  "Whirly-Birdie-Wide": {
    weight: 75,
    width: 150,
    italic: 0,
  },

  "Whirly-Birdie-Wide-Italic": {
    weight: 75,
    width: 150,
    italic: 12,
  },

  "Whirly-Birdie-Wide-Bold": {
    weight: 100,
    width: 150,
    italic: 0,
  },

  "Whirly-Birdie-Wide-Bold-Italic": {
    weight: 100,
    width: 150,
    italic: 12,
  },
  
  "Whirly-Birdie-Wide-Light": {
    weight: 50,
    width: 150,
    italic: 0,
  },

  "Whirly-Birdie-Wide-Light-Italic": {
    weight: 50,
    width: 150,
    italic: 12,
  },
  "Whirly-Birdie-Narrow": {
    weight: 75,
    width: 50,
    italic: 0,
  },
  "Whirly-Birdie-Narrow-Italic": {
    weight: 75,
    width: 50,
    italic: 12,
  },

  "Whirly-Birdie-Narrow-Bold": {
    weight: 100,
    width: 50,
    italic: 0,
  },

  "Whirly-Birdie-Narrow-Bold-Italic": {
    weight: 100,
    width: 50,
    italic: 12,
  },
  
  "Whirly-Birdie-Narrow-Light": {
    weight: 50,
    width: 50,
    italic: 0,
  },

  "Whirly-Birdie-Narrow-Light-Italic": {
    weight: 50,
    width: 50,
    italic: 12,
  },
};

export const smallwords1 = [
  "The",
  "Of",
  "On",
  "Deep",
  "To",
  "Onward",
  "Exact",
  "Haunted",
  "Brave",
  "Small",
  "Quick",
  "True",
  "Dream",
  "London",
  "New York",
  "Paris",
  "Morning",
  "New",
  "Busy",
  "My",
  "Atomic",
  "Swirl",
  "On the",
  "Tour of",
  "Visiting",
  "Visit",
  "Tech",
  "Liminal",
  "Old",
  "Port",
  "Lovely",
  "Fantastic",
  "No",
  "Midnight",
  "Early",
  "Silver",
  "Cute",
  "Perfect",
  "Grumpy",
  "Rainy",
  "Cosmic",
  "Hudson",
  "Dear",
  "Super",
  "Hot",
  "",
  "",
  "",
  "",
];

export const smallwords2 = [
  "Space",
  "Lakes",
  "Thought",
  "Blue",
  "Red",
  "Green",
  "Cube",
  "Cats",
  "Bugs",
  "Lights",
  "Walks",
  "Stars",
  "Lines",
  "Parks",
  "Night",
  "Cave",
  "Glow",
  "Ocean",
  "Moon",
  "Life",
  "Violet",
  "Wing",
  "Eyes",
  "Airway",
  "Door",
  "Dove",
  "Bat",
  "Type",
  "Cup",
  "Art",
  "Place",
  "Why?",
  "Where",
  "Fog",
  "Highway",
  "Sea",
  "Pearl",
  "Letter",
  "Trail",
  "Gift",
  "Whale",
  "Metro",
  "Maps",
  "Moth",
  "Fire",
  "Fortune",
  "Cruise",
  "Cold",
  "Ship",
  "Hall",
  "Rome",
  "Array",
  "Angel",
  "Train",
  "Camera",
  "Bike",
  "Scooter",
  "Portal",
  "Swirl",
  "Cloud",
  "Bliss",
  "Berlin",
  "Parade",
  "Piano",
  "Window",
  "River",
  "Houses",
  "Hill",
  "Ticket",
  "Dot",
  "Chime",
  "Glass",
  "Attic",
  "Ostrich",
  "Joust",
  "Wish",
  "Zinc",
  "Wave",
  "Sounds",
  "Mix",
  "Sun",
  "Fox",
  "Awe",
  "Emu",
  "Hop",
  "Key",
  "Owl",
];

const whirlynames = [
  {
    A: "airship",
    B: "birdie",
    C: "cycle",
    D: "dracula",
    E: "explode",
    F: "fox",
    G: "glitter",
    H: "house for birds",
    I: "imagination",
    J: "jalopy",
    K: "kettle",
    L: "love",
    M: "moth",
    N: "network",
    O: "oscilloscope",
    P: "pole",
    Q: "quay",
    R: "rain",
    S: "sputnik",
    T: "train",
    U: "UFO",
    V: "video",
    W: "whale",
    X: "xissors",
    Y: "yelicopter",
    Z: "98.5 KRZ",

    a: "atom",
    b: "beer",
    c: "cups",
    d: "death",
    e: "explode",
    f: "flower",
    g: "globe",
    h: "hedgehog",
    i: "eye",
    j: "Jeremy the snail",
    k: "Kit Cat",
    l: "lab",
    m: "magic orb",
    n: "notion",
    o: "octopus",
    p: "plant",
    q: "QWERTY",
    r: "robot",
    s: "submarine",
    t: "tree leaves",
    u: "unwritten",
    v: "virtual",
    w: "white diamond",
    x: "pom pom",
    y: "funny bird",
    z: "zleepy",

    0: "calculator",
    1: "sunny sun",
    2: "cursor",
    3: "moon",
    4: "duckie",
    5: "casette recorder",
    6: "paws",
    7: "fish",
    8: "fire",
    9: "sound wave",
  },
];
